var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.error
      ? _c("div", [
          _c("div", { staticClass: "box-content" }, [
            _c("div", { staticClass: "mt-4 mb-4 text-center" }, [
              _c("i", { staticClass: "fal fa-exclamation-circle" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("ERROR.COULD_NOT_USE_INVITE")) +
                  "\n        "
              ),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-btn",
                    { attrs: { variant: "primary", to: { name: "Login" } } },
                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.error
      ? _c("div", [
          !_vm.user.information
            ? _c("div", [
                _vm.information
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.information.image_uuid
                            ? _c("b-img", {
                                staticClass: "org-logo",
                                attrs: {
                                  fluid: "",
                                  src: _vm.organisationImage,
                                  alt: _vm.information.organisation_name,
                                },
                              })
                            : _vm._e(),
                          _vm.information.image_uuid == null
                            ? _c("b-img", {
                                staticClass: "logo",
                                attrs: {
                                  fluid: "",
                                  src: "/img/sefos.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.information.auth_type != ""
                        ? _c(
                            "div",
                            { staticClass: "box-content" },
                            [
                              _vm.loading == false
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      { staticClass: "w-medium pt-0 mt-0" },
                                      [_vm._v(_vm._s(_vm.$t("INVITE.TITLE")))]
                                    ),
                                    _c("dl", { staticClass: "clearfix" }, [
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(_vm.$t("ORGANISATION.TITLE"))
                                        ),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.information.organisation_name
                                          )
                                        ),
                                      ]),
                                      _c("dt", [
                                        _vm._v(_vm._s(_vm.$t("NAME"))),
                                      ]),
                                      _c("dd", [
                                        _vm._v(_vm._s(_vm.information.name)),
                                      ]),
                                      _c("dt", [
                                        _vm._v(_vm._s(_vm.$t("EMAIL"))),
                                      ]),
                                      _c("dd", [
                                        _vm._v(_vm._s(_vm.information.email)),
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(_vm.$t("AUTH_METHODS.TITLE"))
                                        ),
                                      ]),
                                      _vm.information.auth_type != "custom"
                                        ? _c("dd", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "AUTH_METHODS." +
                                                    _vm.information.auth_type
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.information.auth_type == "custom"
                                        ? _c("dd", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.information.custom_name
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ])
                                : _vm._e(),
                              !_vm.loading
                                ? _c(
                                    "div",
                                    { staticClass: "form-group mt-4" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            id: "checkbox-1",
                                            name: "checkbox-1",
                                            switch: "",
                                            value: "1",
                                            "unchecked-value": "0",
                                          },
                                          model: {
                                            value: _vm.accepted,
                                            callback: function ($$v) {
                                              _vm.accepted = $$v
                                            },
                                            expression: "accepted",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "INVITE.ACCEPT_AGREEMENT"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { state: _vm.checkAccepted } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "INVITE.NEED_TO_ACCEPT_AGREEMENT"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _c("p", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: "/agreements/data_processing_agreement.pdf",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "INVITE.data_processing_agreement"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              target: "_blank",
                                              href: "/agreements/end_user_agreement.pdf",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "INVITE.end_user_agreement"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.information.auth_type == "password"
                                ? _c("InvitePassword", {
                                    attrs: {
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.auth_type == "password-sms"
                                ? _c("InvitePasswordSms", {
                                    attrs: {
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.auth_type == "siths-card"
                                ? _c("InviteSiths", {
                                    attrs: {
                                      auth_method: _vm.information.auth_method,
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.auth_type == "sefos-certificate"
                                ? _c("InviteSefosCertificate", {
                                    attrs: {
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.auth_type == "se-eid"
                                ? _c("InviteSeEid", {
                                    attrs: {
                                      auth_method: _vm.information.auth_method,
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.auth_type == "freja-org"
                                ? _c("InviteFrejaOrgId", {
                                    attrs: {
                                      auth_method: _vm.information.auth_method,
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                              _vm.information.auth_type == "custom"
                                ? _c("InviteCustom", {
                                    attrs: {
                                      auth_method: _vm.information.auth_method,
                                      email: _vm.information.email,
                                      accepted: _vm.accepted,
                                      code: _vm.code,
                                    },
                                    on: {
                                      loading: _vm.setLoading,
                                      activated: _vm.activated,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }