<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="step == 1">
        <p class="mt-4">
          {{ $t("INVITE.NEED_TO_SET_PASSWORD_SMS") }}
        </p>
        <div class="form-group">
          <label class="col-form-label">{{ $t("PASSWORD") }}</label>
          <b-form-input trim
            type="password"
            @input="acceptData"
            autocomplete="off"
            v-model="password1"
          ></b-form-input>

          <b-form-invalid-feedback :state="errors.length == 0">
            <div v-for="(item, index) in errors" :key="index">* {{ item }}</div>
          </b-form-invalid-feedback>

          <label class="col-form-label">{{ $t("RETYPE_PASSWORD") }}</label>
          <b-form-input trim
            type="password"
            autocomplete="off"
            v-model="password2"
          ></b-form-input>

          <b-form-invalid-feedback :state="!password_not_matching">
            {{ $t("ERROR.PASSWORD_NOT_MATCHED") }}
          </b-form-invalid-feedback>
        </div>

        <button
          :disabled="!showNextButton"
          v-on:click="inviteSubmit"
          class="btn btn-fill btn-block btn-primary"
        >
          {{ $t("NEXT") }}
        </button>

        <router-link
          class="btn-block btn btn-secondary"
          :to="{ name: 'Login' }"
          >{{ $t("BACK") }}</router-link
        >
      </div>
      <div v-if="step == 2">

      <div class="text-center">
        <div class="caption-title">{{ $t("SMS.SPECIFY_CODE") }}</div>
        <input
          class="mt-2 text-center form-control"
          v-model="sms_code"
          type="tel"
          name="code"
          required
          v-on:keyup.enter="authenticateIdentifier"
          :placeholder="$t('SMS.SPECIFY_CODE_PLACEHOLDER')"
        />
        <div class="mt-4">
          <button
            v-on:click="authenticateIdentifier"
            :disabled="disableVerify"
            class="btn btn-fill btn-block btn-primary"
          >
            {{ $t("VERIFY_BTN") }}
          </button>
        </div>
        <div class="mt-2">
          <button
            v-on:click="cancelSubmit"
            class="mt-2 btn btn-block  btn-secondary"
          >
            {{ $t("CANCEL") }}
          </button>
        </div>
      </div>


      </div>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["code", "accepted", "email"],
  data() {
    return {
      step: 1,
      sms_code: '',
      errors: [],
      loading: true,
      password1: "",
      password2: "",
      errorMessage: "",
      rules: [
        {
          message: this.$t("ERROR.PASSWORD.ONE_LOWERCASE_REQUIRED"),
          regex: /[a-z]+/,
        },
        {
          message: this.$t("ERROR.PASSWORD.ONE_UPPERCASE_REQUIRED"),
          regex: /[A-Z]+/,
        },
        {
          message: this.$t("ERROR.PASSWORD.CHARACTERS_REQUIRED"),
          regex: /.{8,}/,
        },
        {
          message: this.$t("ERROR.PASSWORD.ONE_NUMBER_REQUIRED"),
          regex: /[0-9]+/,
        },
      ],
    };
  },
  methods: { 
    reset: function() {
      this.loading = false;
      this.sms_code = "";
      this.authRef = "";
      this.step = 1;
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      this.reset();
    },
    acceptData: function() {
      if (this.password1 == "") {
        return false;
      }
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.password1)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        this.errors = [];
      } else {
        this.errors = errors;
        return false;
      }
    },
    inviteSubmit: async function() {
      this.loading = true;
      this.$emit("loading", true);
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/invite/activate", {
          code: this.code,
          password: this.password1.trim(),
        })
        .then(async function(result) {
          self.loading = false;
          self.auth_uuid = result.data.auth_uuid;
          self.step = 2;
        })
        .catch(() => {
          self.$emit("loading", true);
          self.loading = false;
        });
    },
    authenticateIdentifier: function() {
      let self = this;
      this.verifying = true;
      this.$http
        .post(this.user.hostname + "/authenticate/verify", {
          auth_uuid: this.auth_uuid,
          code: this.sms_code.trim(),
        })
        .then(function(result) {
          self.$emit("activated", result.data.token);
        })
        .catch(function() {
          self.step = 1;
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    disableVerify: function() {
      return this.sms_code.length != 6;
    },
    checkAccepted() {
      return this.accepted == 1;
    },
    showNextButton() {
      if (this.errors.length != 0) return false;
      if (this.checkAccepted == false) return false;
      if (this.password1 == "") return false;
      if (this.password_not_matching == true) return false;
      return true;
    },
    password_not_matching() {
      return this.password1 != this.password2;
    },
  },
  mounted: function() {
    this.loading = false;
  },
};
</script>
<style></style>
