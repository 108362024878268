<template>
  <section class="mt-2">
    <div v-if="!loading">

      <b-media vertical-align="center" class="mb-2" v-if="options.length == 0">
        <template #aside>
          <b-img
            src="/img/auth/se-eid.png"
            :alt="$t('AUTH_METHODS.se-eid')"
            class="ml-4 mt-2"
          ></b-img>
        </template>
        <p class="mt-4">
          {{ $t("INVITE.NEED_TO_AUTHENTICATE") }}<br />
          {{ $t("AUTH_METHODS.se-eid") }}
        </p>
      </b-media>


      <b-media vertical-align="center" class="mb-2" v-if="auth_method == '' && options.length != 0">
        <template #aside>
          <b-img
            v-if="checkMethods('freja')"
            src="/img/auth/freja.png"
            :alt="$t('AUTH_METHODS.freja')"
            class="mt-2"
          ></b-img>
          <b-img
            v-if="checkMethods('bankid-se')"
            src="/img/auth/bankid-se.png"
            :alt="$t('AUTH_METHODS.bankid-se')"
            class="ml-4 mt-2"
          ></b-img>
        </template>
        <p class="mt-4">
          {{ $t("INVITE.NEED_TO_AUTHENTICATE") }}<br />
          {{ $t("AUTH_METHODS.se-eid") }}
        </p>
      </b-media>

      <b-form-select
        v-if="auth_method == '' && options.length != 0"
        v-model="authentication_method"
        :options="options"
        class="mb-2"
      ></b-form-select>

    </div>

    <section v-if="!authentication_method && options.length != 0" class="mt-2">
      <b-button disabled class="btn btn-primary btn-block">{{ $t("NEXT") }}</b-button>
      <router-link class="btn btn-secondary btn-block" :to="{ name: 'Login' }">{{
        $t("BACK")
      }}</router-link>
    </section>

    <section v-if="options.length == 0" class="mt-2">
      <b-overlay :show="redirect" rounded="sm">
        <button
            :disabled="!checkAccepted"
            @click="inviteSubmit"
            class="btn btn-fill btn-block btn-primary"
          >
            {{ $t("NEXT") }}
          </button>
      </b-overlay>
    </section>

    <Bankid
      :auth_method="auth_method"
      :email="email"
      @loading="setLoading"
      @activated="activated"
      :accepted="accepted"
      :code="code"
      v-if="showMethod('bankid-se')"
    ></Bankid>
    <Freja
      :auth_method="auth_method"
      :email="email"
      @loading="setLoading"
      @activated="activated"
      :accepted="accepted"
      :code="code"
      v-if="showMethod('freja')"
    ></Freja>
  </section>
</template>
<script>
import Freja from "@/components/Invite/Activate/SE/Freja.vue";
import Bankid from "@/components/Invite/Activate/SE/Bankid.vue";
export default {
  components: { Freja, Bankid },
  props: ["code", "accepted", "email", "auth_method"],
  data() {
    return {
      redirect: false,
      authentication_method: null,
      loading: false,
      options: [],
      methods: null
    };
  },
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("loading", value);
    },
    activated(data) {
      this.$emit("activated", data);
    },
    inviteSubmit: async function() {
      this.loading = true;
      this.$emit("loading", true);
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/invite/activate", {
          code: this.code
        })
        .then(function(result) {
          self.loading = false;
          self.redirect = true;
          window.location.href = result.data.request_url;
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
    popuplateMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        if (method.name == "se-eid") {
          if (method.childs.length > 1) {
            this.options.push({
              value: null,
              text: this.$t("AUTH_METHODS.SELECT_EXTRA_METHOD"),
            });
          }
          for (let ix = 0; ix < method.childs.length; ix++) {
            let childMethod = method.childs[ix];
            if (method.childs.length == 1) {
              this.authentication_method = childMethod.name;
            }
            if (childMethod.name != "") {
              this.options.push({
                value: childMethod.name,
                text: this.$t("AUTH_METHODS." + childMethod.name),
              });
            }
          }
        }
      }
    },
    checkMethods(item) {
      for (let ix = 0; ix < this.options.length; ix++) {
        if (this.options[ix].value == item) {
          return true;
        }
      }
      return false;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/methods/list")
        .then(function(result) {
          self.methods = result.data;
          self.popuplateMethods();
        })
        .catch(function() {
          self.loading = false;
        });
    },
    showMethod(method) {
      if (this.auth_method == "") {
        if (this.authentication_method == method) {
          return true;
        }
      }
      if (this.auth_method == method) {
        return true;
      }
      return false;
    },
  },
  computed: {
    showBack() {
      return this.authentication_method == null && this.auth_method == "";
    },
    checkAccepted() {
      return this.accepted == 1;
    },
  },
  mounted: function() {
    this.getMethods();
  },
};
</script>
<style></style>
