<template>
  <section>
    <div v-if="!loading" class="mt-2">
      <b-media
        vertical-align="center"
        class="mb-2"
        v-if="auth_method == 'bankid-se'"
      >
        <template #aside>
          <b-img src="/img/auth/bankid-se.png" :alt="$t('AUTH_METHODS.bankid-eid')" class="mt-2"></b-img>
        </template>
        <p class="mt-4">
          {{ $t("INVITE.NEED_TO_AUTHENTICATE") }}
          {{ $t("AUTH_METHODS.bankid-se") }}
        </p>
      </b-media>
      <p>
        <b-overlay :show="redirect" rounded="sm">
          <button
            :disabled="!checkAccepted"
            v-on:click="inviteSubmit"
            class="btn btn-fill btn-primary btn-block"
          >
            {{ $t("NEXT") }}
          </button>
          <router-link class="btn btn-block btn-secondary" :to="{ name: 'Login' }">{{
            $t("BACK")
          }}</router-link>

        </b-overlay>
      </p>
    </div>
    <div class="text-center" v-if="identifier">
      <div v-if="hintMessage == 'outstandingTransaction'">
        <div v-if="!showOnThisDevice">
          1. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step1") }}.<br />
          2. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step2") }}.<br />
          3. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step3") }}.<br />
          4. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step4") }}.<br />

          <qrcode-vue
            class="qrcode mt-2 mb-2"
            v-if="identifier != ''"
            :value="qrCode"
            level="H"
            size="200"
            margin="2"
            :key="identifier"
          ></qrcode-vue>
          <a variant="primary" class="btn-block" :href="bankidUrlDevice">{{
            $t("SAME_DEVICE")
          }}</a>
        </div>

        <div class="mt-2 mb-2" v-if="showOnThisDevice">
          <a variant="primary" class="btn btn-primary btn-fill text-white" :href="bankidUrlDevice">
            {{ $t("SAME_DEVICE") }}
          </a>
          <br />
          <br />
          <a href="#" click.prevent="otherDevice"> {{ $t("OTHER_DEVICE") }}</a>
        </div>
      </div>

      <div v-if="hintMessage != 'outstandingTransaction'">
        <h5>{{ statusMessage }}</h5>
      </div>

      <b-button variant="secondary" class="mt-2 btn-block" v-on:click="cancelSubmit">
        {{ $t("CANCEL") }}
      </b-button>
    </div>
  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
import QrcodeVue from "qrcode.vue";
export default {
  components: { QrcodeVue },
  props: ["code", "accepted", "email", "auth_method"],
  data() {
    return {
      redirect: false,
      device: mobileDetect,
      loading: true,
      errorMessage: "",
      auth_uuid: "",
      identifier: "",
      qrCode: "",
      statusMessage: "",
      hintMessage: "outstandingTransaction",
      timer: "",
      showOnThisDevice: false,
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.identifier = "";
      this.showOnThisDevice = this.device.isMobile;
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      let self = this;
      self.reset();
      this.$http.get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid);
    },
    hideModal: function() {
      this.$emit("hideModal");
    },
    inviteSubmit: async function() {
      this.loading = true;
      this.$emit("loading", true);
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/invite/activate", {
          code: this.code,
          method: "bankid-se",
        })
        .then(function(result) {
          if (result.data.redirect == 1) {
            self.loading = false;
            self.redirect = true;
            window.location.href = result.data.request_url;
          } else {
            self.statusMessage = "";
            self.auth_uuid = result.data.auth_uuid;            
            self.qrCode = result.data.qrCode;
            if (result.data.autoStartToken != undefined) {
              self.identifier = result.data.autoStartToken;
            } else {
              self.identifier = result.data.identifier;
            }
            self.timer = setTimeout(self.checkStatus(), 1000);
          }
        })
        .catch(function() {
          self.reset();
        });
    },
    checkStatus: function() {
      let self = this;
      if (this.identifier != "") {
        this.loading = true;
        this.$http
          .get(this.user.hostname + "/authenticate/status/" + this.auth_uuid)
          .then(function(result) {
            if (result.status == 200) {
              clearTimeout(self.timer);
              self.verifyAuthentication();
            }
            if (result.status == 202) {            
              if (result.data != "") {
                if (result.data.hintCode == "startFailed") {
                  self.reset();
                } else {
                  self.qrCode = result.data.qrCode;
                  self.hintMessage = result.data.hintCode;
                  self.statusMessage = self.$t(
                    "BANKID_SE.PROCESS." + result.data.hintCode
                  );
                }
                self.timer = setTimeout(function() {
                      self.checkStatus();
                    }, 1500);
              }
            }
          })
          .catch(function(error) {
            self.reset();
            self.hintMessage = error.response.data;
          });
      }
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/invite/activate/result/" + this.auth_uuid)
        .then(async function(result) {
          self.$emit("activated", result.data.token);
        })
        .catch(function() {
          self.reset();
        });
    },
  },
  computed: {
    checkAccepted() {
      return this.accepted == 1;
    },
    bankidUrlDevice() {
      if (this.device.isMobile) {
        return (
          "https://app.bankid.com/?autostarttoken=" +
          this.identifier +
          "&redirect=null"
        );
      } else {
        return this.bankidUrl;
      }
    },
    bankidUrl() {
      return "bankid:///?autostarttoken=" + this.identifier + "&redirect=null";
    },
  },
  mounted: function() {
    this.loading = false;
    this.showOnThisDevice = this.device.isMobile;
  },
};
</script>
<style></style>
