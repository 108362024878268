var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "mt-2" },
          [
            _vm.auth_method == "bankid-se"
              ? _c(
                  "b-media",
                  {
                    staticClass: "mb-2",
                    attrs: { "vertical-align": "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "aside",
                          fn: function () {
                            return [
                              _c("b-img", {
                                staticClass: "mt-2",
                                attrs: {
                                  src: "/img/auth/bankid-se.png",
                                  alt: _vm.$t("AUTH_METHODS.bankid-eid"),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1016699056
                    ),
                  },
                  [
                    _c("p", { staticClass: "mt-4" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("INVITE.NEED_TO_AUTHENTICATE")) +
                          "\n        " +
                          _vm._s(_vm.$t("AUTH_METHODS.bankid-se")) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "p",
              [
                _c(
                  "b-overlay",
                  { attrs: { show: _vm.redirect, rounded: "sm" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-fill btn-primary btn-block",
                        attrs: { disabled: !_vm.checkAccepted },
                        on: { click: _vm.inviteSubmit },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("NEXT")) + "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-block btn-secondary",
                        attrs: { to: { name: "Login" } },
                      },
                      [_vm._v(_vm._s(_vm.$t("BACK")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.identifier
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _vm.hintMessage == "outstandingTransaction"
              ? _c("div", [
                  !_vm.showOnThisDevice
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            "\n        1. " +
                              _vm._s(
                                _vm.$t(
                                  "BANKID_SE.PROCESS.outstandingTransaction.step1"
                                )
                              ) +
                              "."
                          ),
                          _c("br"),
                          _vm._v(
                            "\n        2. " +
                              _vm._s(
                                _vm.$t(
                                  "BANKID_SE.PROCESS.outstandingTransaction.step2"
                                )
                              ) +
                              "."
                          ),
                          _c("br"),
                          _vm._v(
                            "\n        3. " +
                              _vm._s(
                                _vm.$t(
                                  "BANKID_SE.PROCESS.outstandingTransaction.step3"
                                )
                              ) +
                              "."
                          ),
                          _c("br"),
                          _vm._v(
                            "\n        4. " +
                              _vm._s(
                                _vm.$t(
                                  "BANKID_SE.PROCESS.outstandingTransaction.step4"
                                )
                              ) +
                              "."
                          ),
                          _c("br"),
                          _vm.identifier != ""
                            ? _c("qrcode-vue", {
                                key: _vm.identifier,
                                staticClass: "qrcode mt-2 mb-2",
                                attrs: {
                                  value: _vm.qrCode,
                                  level: "H",
                                  size: "200",
                                  margin: "2",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "btn-block",
                              attrs: {
                                variant: "primary",
                                href: _vm.bankidUrlDevice,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("SAME_DEVICE")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showOnThisDevice
                    ? _c("div", { staticClass: "mt-2 mb-2" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary btn-fill text-white",
                            attrs: {
                              variant: "primary",
                              href: _vm.bankidUrlDevice,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("SAME_DEVICE")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c("br"),
                        _c("br"),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "#",
                              "click.prevent": "otherDevice",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("OTHER_DEVICE")))]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.hintMessage != "outstandingTransaction"
              ? _c("div", [_c("h5", [_vm._v(_vm._s(_vm.statusMessage))])])
              : _vm._e(),
            _c(
              "b-button",
              {
                staticClass: "mt-2 btn-block",
                attrs: { variant: "secondary" },
                on: { click: _vm.cancelSubmit },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("CANCEL")) + "\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }